// import $ from 'jquery';
// // import clickOutside from './clickOutside';
// import flatpickr from 'flatpickr';
// // import moment from 'moment';
// import 'flatpickr/dist/l10n/es';
// import 'flatpickr/dist/l10n/de';
// import 'flatpickr/dist/flatpickr.css';

// function datepicker () {
    $(document).ready(function() {
        $(".js-datepicker").flatpickr(datepickerOptions);
    });
// }

// export default datepicker();
