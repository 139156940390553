// import $ from 'jquery';

// function _toggleClass () {
    var element = $('[data-toggle-class]');

    element.click(function (e) {
        e.preventDefault();
        var _this = this;
        var _class = $(this).data('toggle-class');
        var _item = $(this).data('toggle-class-item');
        var _tThis = $(this).data('toggle-class-this');
        var _items = $('[data-toggle-class-item]:not([data-toggle-class])');

        if (_tThis) {
            _items = $('[data-toggle-class-item]');
        }

        _items.each(function () {
            var _itemName = $(this).data('toggle-class-item');
            if (_item == _itemName) {
                $(this).toggleClass(_class);
            }
        });
    });
// }

// export default _toggleClass();
