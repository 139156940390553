
// metemos data-redirect en el container si no hay audio para reproducir pero SI URL siguiente
// if ($('.c-speech').data('redirect')) {
//     setTimeout(function () {
//         window.location.href = $('.c-speech').data('redirect')
//     }, 5000)
// }

if ('speechSynthesis' in window && $('.c-speech').length) {
            
    var lang = $('html').attr('lang')
    var text = $('#speech_text').text()
    var synth = window.speechSynthesis
    var speech = new SpeechSynthesisUtterance()
    speech.lang = (lang === 'es'?'es-ES':'en-EN')
    speech.text = text
    speech.volume = 0.5
    speech.rate = 1 // voice speed
    speech.pitch = 0.6 // voice tone
    synth.cancel() // clear reproduction queue
    // synth.speak(speech) // always init speech // deprecated without user activation
    
    window.speechSynthesis.onvoiceschanged = () => {
        console.log('onvoiceschanged')
        var voice = null
        var voices = window.speechSynthesis.getVoices()

        voices.forEach(function(item, index) {
            if (lang === 'es' && item.name.includes('Paulina')) {
                voice = item
            }

            if (lang === 'en' && item.name.includes('Google UK English Female')) {
                voice = item
            }
        })
        
        speech.voice = voice
        console.log('voice setted to ' + voice.name)
    }
    speech.onstart = () => {
        console.log('onstart')
        changeIcon_fromPlay_toPause()
    }
    speech.onpause = () => {
        console.log('onpause')
        changeIcon_fromPause_toPlay()
    }
    speech.onresume = () => {
        console.log('onresume')
        changeIcon_fromPlay_toPause()
    }
    speech.onend = () => {
        console.log('onend')
        changeIcon_fromPause_toPlay()
    }
    
    $(document).on('click','.js-speech-main-button[play]', function() {
        if (!synth.speaking) { // only first time or when cancelled on volume change
            console.log('play - start')
            synth.cancel()
            synth.speak(speech)
        } else {
            console.log('play - resume')
            synth.resume()
        }
        // setTimeout(function(){ console.log('play', synth) }, 1000)
    })
    
    $(document).on('click','.js-speech-main-button[pause]', function() {
        console.log('pause')
        synth.pause()
        // setTimeout(function(){ console.log('pause', synth) }, 1000) // esperar un poco a que termine la ultima palabra que esta pronunciando
    })

    // HELPERS FUNCTIONS
    function changeIcon_fromPlay_toPause() {
        // change main button to play icon
        $('.js-speech-main-button').removeAttr('play')
        $('.js-speech-main-button').attr('pause','')
    }
    
    function changeIcon_fromPause_toPlay() {
        // change main button to pause icon
        $('.js-speech-main-button').removeAttr('pause')
        $('.js-speech-main-button').attr('play','')
    }

}
